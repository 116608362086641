import $ from 'jquery';

$(() => {
  if (!$('.product-carousel').length) {
    return;
  }

  require.ensure(['./core/product-carousel.js'], require => {
    require('slick-carousel/slick/slick');
    require('./core/product-carousel');
  });
});
