import $ from 'jquery';
import elementHelper from '@marshalls/nativejselementhelper/NativeJsElementHelper';
import { EventHelper } from "@marshalls/eventhelper";
import throttle from 'lodash/throttle';

const observablePictureClass = 'js-dom-observable--picture';
const replacementClass = 'object-fit-replacement';
const objectFitClass = 'object-fit';


class DOMImageObserver {
  constructor() {
    this.$window = $(window);
  }

  loadPictures($divs) {
    const jqDivs = $($divs);
    if (('IntersectionObserver' in window) && ('IntersectionObserverEntry' in window) && ('intersectionRatio' in window.IntersectionObserverEntry.prototype) && Modernizr.objectfit) {
      makePictures(jqDivs);
    } else {
      loadAllPictures(jqDivs, this.$window);
    }

    this.$window.on('resize', throttle(() => {
      $(`.${this.replacementClass}`).each((index, item) => {
        const $div = $(item);
        const $picture = $div.find('picture');

        if ($picture.length) {
          $div.css('background-image', `url(${getPictureSource($picture, this.$window)})`);
        }
      });
    }, 100));
  }

  loadImages($imageHolders, holderClass) {
    loadObserver($imageHolders.find('img'), holderClass);
  }
}

function makePictures($divs) {
  $divs.each((index, item) => {
    const $div = $(item);
    const media = $div.data("media");

    if (media) {
      const defaultImage = $div.data("default-image");
      const altText = $div.data("alt-text");
      const objectFit = $div.data(objectFitClass);
      const transforms = $div.data("transforms");
      const imageClass = objectFit ? ` ${objectFitClass}` : '';
      let extraClasses = $div.data("picture-classes");
      extraClasses = extraClasses ? ` ${extraClasses}` : '';

      const $picture = $(`
<picture class="${observablePictureClass}${extraClasses}">
  <source media="${media.size1}" srcset="${defaultImage}" data-srcset="${transforms.transform1}">
  <source media="${media.size2}" srcset="${defaultImage}" data-srcset="${transforms.transform2}">
  <source media="${media.size3}" srcset="${defaultImage}" data-srcset="${transforms.transform3}">
  <source media="${media.size4}" srcset="${defaultImage}" data-srcset="${transforms.transform4}">
  <source media="${media.size5}" srcset="${defaultImage}" data-srcset="${transforms.transform5}">
  <img class="image${imageClass}" alt="${altText}" src="${defaultImage}" data-src="${transforms.transform5}">
</picture>`);

      $div.replaceWith($picture);
    }
  });

  loadObserver($(`.${observablePictureClass}`).find('img'), observablePictureClass);
}

function loadObserver($images, parentClass) {
  const threshold = !!navigator.userAgent.match(/firefox/i) ? 0 : 0.01; // eslint-disable-line no-extra-boolean-cast
  const options = {
    root: null,
    rootMargin: "0px",
    threshold,
  };
  const observer = new IntersectionObserver(observerCallback, options);
  const dataAttribute = "is-observed";

  $images.each((index, element) => {
    const $element = $(element);

    if (!$element.data(dataAttribute) && $element.parent().hasClass(parentClass)) {
      observer.observe(element);
      $element.data(dataAttribute, true);
    }
  });
}

function observerCallback(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const target = entry.target;
      loadPicture($(target).parent());

      observer.unobserve(target);
    }
  });

  EventHelper.dispatchEvent("domObserverHandlePictureIntersectionEnd", document);
}

function loadAllPictures($divs, $window) {
  $divs.each((index, element) => {
    const $element = $(element);
    const objectFit = $element.data(objectFitClass);
    const $picture = $($element.next('noscript').text()); // have to use text as .html gets html entities

    if (Modernizr.objectfit || !objectFit) {
      $element.replaceWith($picture);
    } else {
      const $replacementDiv = $(elementHelper.createElement('div', replacementClass));

      $replacementDiv
        .css('background-image', `url(${getPictureSource($picture, $window)})`)
        .append($picture.addClass(`${objectFitClass}-replaced`));

      $element.replaceWith($replacementDiv);
    }
  });
}

function loadPicture($picture) {
  $picture.children().each((index, item) => {
    const $item = $(item);

    if ($item.is('source')) {
      $item.attr('srcset', $item.data('srcset'));
    } else if ($item.is('img')) {
      $item.attr('src', $item.data('src'));
    }
  });

  $picture.removeClass(observablePictureClass);
}

function getPictureSource($picture, $window) {
  const $sources = $picture.children('source');
  let source = $sources.last().attr('srcset'); // this assumes sources are done normally with desktop largest last (default to desktop)

  if ($sources.length === 5) {
    const windowWidth = $window.outerWidth();

    if (windowWidth < 380) {
      source = $sources.eq(0).attr('srcset');
    } else if (windowWidth < 767) {
      source = $sources.eq(1).attr('srcset');
    } else if (windowWidth < 1200) {
      source = $sources.eq(2).attr('srcset');
    } else if (windowWidth < 1600) {
      source = $sources.eq(3).attr('srcset');
    } else {
      source = $sources.eq(4).attr('srcset');
    }
  }

  return source;
}

const instance = new DOMImageObserver();

export default instance;
