import $ from 'jquery';
import mobileChecker from '@marshalls/mobilechecker/MobileChecker';

$(() => {
  const openClass = 'open';
  const sectionOpenClass = 'open-section';
  const bodyClass = 'no-scroll';
  const closeClass = 'site-header__section-close';
  const $body = $('body');
  const $header = $('.site-header');
  const $link = $('.site-header__link');
  const navFilterBack = 'site-header__extended-filter-holder-back';
  const navFilterClose = 'site-header__extended-filter-holder-close';
  const $mobileNav = $('.site-header__navigation-holder');
  const mobileNavShow = 'site-header__navigation-holder__hide'
  const $mobileSearch = $header.find('#site-header__mobile-search');
  const $mobileSearchForm = $('.site-header__search-holder-mobile');
  const mobileSearchClass = 'site-header__search-holder-mobile__hide';
  let resizeTimout;
  let $clone;

  $header.on('click', `.${closeClass}`, () => {
    $header.removeClass(sectionOpenClass);
    $mobileNav.removeClass(mobileNavShow);
    $clone.remove();
  });

  $body.on('click', `.${navFilterClose}`, () => {
    hideExtendedNav();
  });

  $body.on('click', `.${navFilterBack}`, () => {
    hideExtendedNav();
  });

  $link.on('click', function(e) {
    e.preventDefault();
    const $thisLink = $(e.currentTarget);
    if ($thisLink.data('filter')) {
      const filtersElement = `#site-header__extended-filters-${$thisLink.data('key')}`;
      const $display = $('.site-header__extended-filter-holder');
      $display.first().html($(filtersElement).first().html());
      $display.prepend(`<div class="site-header__extended-filter-holder-back">Back</div>`);
      $display.prepend(`<div class="site-header__extended-filter-holder-close"></div>`);
      $display.addClass('site-header__extended-filter-holder-show');
    }
    else {
      $(location).attr("href", $thisLink.attr("href"));
    }
  });

  const $burger = $header.find('.site-header__burger-holder');

  $burger.on('click', () => {
    $header.toggleClass(openClass);
    $body.toggleClass(bodyClass);
    $mobileNav.toggleClass(mobileNavShow);

    if ($header.hasClass(sectionOpenClass)) {
      $header.removeClass(sectionOpenClass);
      $clone.remove();
    }

    if (!$header.hasClass(openClass)) {
      hideExtendedNav();
    }
  });

  $mobileSearch.on('click', () => {
    $mobileSearchForm.toggleClass(mobileSearchClass);
  });

  $(window).on('resize', () => {
    clearTimeout(resizeTimout);

    resizeTimout = setTimeout(closeMobile, 125);
  });

  function closeMobile() {
    if (mobileChecker.isDesktop() && $header.hasClass(openClass)) {
      $header.removeClass(openClass);
      $body.removeClass(bodyClass);

      if ($header.hasClass(sectionOpenClass)) {
        $header.removeClass(sectionOpenClass);
        $clone.remove();
      }
    }
  }

  function hideExtendedNav() {
    const workingClass = 'site-header__extended-filter-holder-show';
    const $display = $('.site-header__extended-filter-holder');
    if ($display.hasClass(workingClass)) {
      $display.first().html(``);
      $display.removeClass(workingClass);
    }
  }
});
