import $ from 'jquery';

$(() => {
  if (!$(".usp-list").length) {
    return;
  }
  require.ensure(['./core/uspBanner.js'], require => {
    require('slick-carousel/slick/slick');
    require('./core/uspBanner.js');
  });
});
