import axios from 'axios';
import Cookies from 'js-cookie';
import imageObserver from '../classes/DOMImageObserver';

require('../components/base');
require('../components/init-uspBanner');

const Velocity = require('velocity-animate');

const minSearchLength = 2;
const searchSuggestionsMaxResults = 5;
const previousSearchCookieName = 'stonemarket-previousSearches';
const previousSearchMaxResults = 5;
const siteBase = window.location.origin;
const siteHeader = document.getElementById("site-header");
const searchEntryFieldDesktop = "site-search_entry-header-desktop";
const searchEntryFieldMobile = "site-search_entry-header-mobile";
const searchEntryFormDesktop = "site-header__search-form-desktop";
const searchEntryFormMobile = "site-header__search-form-mobile";
const searchHistoryClearDesktop = "site-search_entry-info-history-clear-desktop";
const searchHistoryClearMobile = "site-search_entry-info-history-clear-mobile";
const searchTipsDesktop = document.getElementById("site-header_search-box-helper-desktop");
const searchTipsMobile = document.getElementById("site-header_search-box-helper-mobile");
const filterItemHTML = `<li class="searchresults-product-filter__selected-list-item">
                          <span class="searchresults-product-filter__selected-filter" data-value="¬VALUE¬">¬NAME¬</span>
                        </li>`;

const previousWrapperDesktop = document.getElementById("site-header__search-box-helper-history-desktop");
const previousBodyDesktop = document.getElementById("site-header__search-box-helper-history-body-desktop");
const previousWrapperMobile = document.getElementById("site-header__search-box-helper-history-mobile");
const previousBodyMobile = document.getElementById("site-header__search-box-helper-history-body-mobile");
const suggestWrapperDesktop = document.getElementById("site-header__search-box-helper-suggestions-desktop");
const suggestBodyDesktop = document.getElementById("site-header__search-box-helper-suggestions-body-desktop");
const suggestWrapperMobile = document.getElementById("site-header__search-box-helper-suggestions-mobile");
const suggestBodyMobile = document.getElementById("site-header__search-box-helper-suggestions-body-mobile");

const siteHeaderOverlay = "site-header__overlaid";
const siteHeaderInfoShow = "site-header__searchform-info-show";

const appliedFilters = [];
let submitDone = false;
let filterAdded = false;
let previousSearches = [];

function visibilityToggle(parentID, childID) {
  if (document.getElementById(parentID) != null) {
    document.getElementById(parentID).addEventListener('click', function () {
      const block = document.getElementById(childID);
      const show = document.getElementById(childID).classList.contains('hidden');
      if (show) {
        Velocity(block, 'slideDown', { duration: 400, easing: 'easeOut' });
        document.getElementById(childID).classList.remove('hidden');
        document.getElementById(parentID).classList.remove('search-results-section__title-expanding');
        document.getElementById(parentID).classList.add('search-results-section__title-collapsing');
      }
      else {
        Velocity(block, 'slideUp', { duration: 400, easing: 'easeOut' });
        document.getElementById(childID).classList.add('hidden');
        document.getElementById(parentID).classList.remove('search-results-section__title-collapsing');
        document.getElementById(parentID).classList.add('search-results-section__title-expanding');
      }
    })
  }
}
function getSearchTipsDesktop(e) {
  if (e.code !== "Enter") {
    getSearchTips(this.value, "desktop");
  }
  else {
    submitDone = true;
    siteHeader.classList.add(siteHeaderOverlay);
    searchTipsDesktop.classList.remove(siteHeaderInfoShow);
  }
}
function getSearchTipsMobile(e) {
  if (e.code !== "Enter") {
    getSearchTips(this.value, "mobile");
 }
  else {
    submitDone = true;
    siteHeader.classList.add(siteHeaderOverlay);
    searchTipsMobile.classList.remove(siteHeaderInfoShow);
  }
}
function getSearchTips(searchTerm, searchType) {
  if (searchTerm && searchTerm.length >= minSearchLength) {
      const url = `${siteBase}/site-search/helpers?search=${encodeURI(searchTerm)}`;
      axios.get(url)
        .then(function (response) {
          if (!submitDone) {
            buildSuggestions(response.data.SearchSuggestions, searchType)
            getSearchHistory();
            buildPreviousSearches(previousSearches, searchType)
          }
        })

      siteHeader.classList.remove(siteHeaderOverlay);
      searchTipsDisplaySet(searchType, true);
  }
  else {
    searchTipsDisplaySet(searchType, false);
  }
}
function searchTipsDisplaySet(searchType, show) {
  if (searchType.toLowerCase() === "desktop" && show) {
    searchTipsDesktop.classList.add(siteHeaderInfoShow);
  }
  else if (searchType.toLowerCase() === "desktop" && !show) {
    searchTipsDesktop.classList.remove(siteHeaderInfoShow);
  }
  else if (searchType.toLowerCase() !== "desktop" && show) {
    searchTipsMobile.classList.add(siteHeaderInfoShow);
  }
  else {
    searchTipsMobile.classList.remove(siteHeaderInfoShow);
  }
}
function buildSuggestions(listIn, viewType) {
  let webCode = '';
  if (listIn) {
    const listWork = listIn.length > searchSuggestionsMaxResults ? listIn.take(searchSuggestionsMaxResults) : listIn;
    if (listWork.length > 0) {
      for (const item of listWork) {
        webCode += `<p><a href="${item.Url}">${item.Title}</a></p><br/>`
      }
      webCode += '<input type="submit" value="View All Results" />';
    }
    else {
      webCode += '<p>No suggestions found</p>';
    }
  }
  else {
    webCode += '<p>No suggestions found</p>';
  }

  if (viewType.toLowerCase() === "desktop") {
    suggestBodyDesktop.innerHTML = webCode;
    suggestWrapperDesktop.style.display = "block";
  } else {
    suggestBodyMobile.innerHTML = webCode;
    suggestWrapperMobile.style.display = "block";
  }

}
function buildPreviousSearches(listIn, viewType) {
  const baseURL = viewType.toLowerCase() === "desktop" ? document.getElementById(searchEntryFormDesktop).action : document.getElementById(searchEntryFormMobile).action;
  let webCode = '';
  if (listIn && listIn.length > 0) {
    const listWork = listIn.length > previousSearchMaxResults ? listIn.take(previousSearchMaxResults) : listIn;
    for (const item of listWork) {
      webCode += `<p><a href="${baseURL}?searchTerm=${encodeURI(item)}">${item}</a></p><br/>`
    }

    if (viewType.toLowerCase() === "desktop") {
      webCode += `<a class="orange" id="${searchHistoryClearDesktop}">Clear All</a>`;
      previousBodyDesktop.innerHTML = webCode;
      document.getElementById(searchHistoryClearDesktop).addEventListener('click', clearSearchHistory, false);
      previousWrapperDesktop.style.display = "block";
    } else {
      webCode += `<a class="orange" id="${searchHistoryClearMobile}">Clear All</a>`;
      previousBodyMobile.innerHTML = webCode;
      document.getElementById(searchHistoryClearMobile).addEventListener('click', clearSearchHistory, false);
      previousWrapperMobile.style.display = "block";
    }
  } else {
    previousWrapperDesktop.style.display = "none";
    previousWrapperMobile.style.display = "none";
  }
}

function getSearchHistory() {
  const checkForPreviousSearches = Cookies.get(previousSearchCookieName);
  if (checkForPreviousSearches !== undefined) {
    const cookieJSON = Cookies.getJSON(previousSearchCookieName);
    previousSearches = cookieJSON;
  }
}
function addSearchHistory(searchTerm) {
  getSearchHistory();
  if (previousSearches.length === 0) {
    const searchArray = [];
    searchArray.push(searchTerm);
    Cookies.set(previousSearchCookieName, searchArray, { expires: 9999, sameSite: 'strict' });
  }
  else {
    if (previousSearches.includes(searchTerm)) {
      const index = previousSearches.indexOf(searchTerm);

      if (index > -1) {
        previousSearches.splice(index, 1);
      }
    }

    if (previousSearches.length === previousSearchMaxResults) {
      previousSearches.splice(previousSearchMaxResults - 1, 1);
    }

    previousSearches.unshift(searchTerm);
    Cookies.set(previousSearchCookieName, previousSearches, { expires: 9999, sameSite: 'strict' });
  }
}
function clearSearchHistory() {
  const searchArray = [];
  Cookies.set(previousSearchCookieName, searchArray, { expires: 9999, sameSite: 'strict' });
  previousBodyDesktop.innerHTML = "";
  previousWrapperDesktop.style.display = "none";
  previousBodyMobile.innerHTML = "";
  previousWrapperMobile.style.display = "none";
}

function updateBrowserUrl(queryName, queryValue, isAdded, updateCurrent = false) {
  const startUrl = window.location.href;
  const currentUrl = startUrl.split('?');
  let updatedUrl = currentUrl[0];
  const updatedQuery = [];

  if (currentUrl.length === 1) {
    if (isAdded) {
      updatedUrl = `${updatedUrl}?${queryName}=${encodeURIComponent(queryValue)}`;
    }
  } else {
    const currentQuery = currentUrl[1].split('&');
    const newQueryStart = `${queryName}=`;
    const newQueryPart = newQueryStart + encodeURIComponent(queryValue);

    let newValueAdded = false;

    for (const query of currentQuery) {
      if (updateCurrent && query.indexOf(newQueryStart) === 0) {
        newValueAdded = true;
        updatedQuery.push(newQueryPart);
      } else if (!(!isAdded && query === newQueryPart)) {
        updatedQuery.push(query);
      }
    }

    if (isAdded && !newValueAdded) {
      updatedQuery.push(newQueryPart);
    }

    if (updatedQuery.length > 0) {
      updatedUrl = `${updatedUrl}?${updatedQuery.join('&')}`;
    }
  }

  if (startUrl !== updatedUrl) {
    filterAdded = true;
  }
  window.history.replaceState(null, null, updatedUrl);
}
function setAppliedFilters() {
  const startUrl = window.location.href;
  const currentUrl = startUrl.split('?');
  if (currentUrl.length !== 1) {
    const currentQuery = currentUrl[1].split('&');
    const newQueryStart = `filters=`;

    for (const query of currentQuery) {
      if (query.indexOf(newQueryStart) >= 1) {
        appliedFilters.push(query.replace(newQueryStart));
      }
    }
  }
}
function showAppliedFilters() {
  if (document.body.contains(document.getElementById("searchresults_appliedfilters_holder"))) {
    if (appliedFilters && appliedFilters.length > 0) {
      const filterList = document.getElementById("searchresults_appliedfilters_entries");
      for (const filter of appliedFilters) {
        const thisFilter = filter.split('___');
        filterList.insertAdjacentHTML("beforeend", filterItemHTML.replace('¬VALUE¬',filter).replace('¬NAME¬', thisFilter[1]));
      }
      const filterHtml = `<li class="searchresults-product-filter__selected-list-item"><a href="#" class="searchresults-product-filter__selected-clear">Clear all</a></li>`;
      filterList.insertAdjacentHTML("beforeend", filterHtml);
      document.getElementById("searchresults_appliedfilters_holder").classList.remove("searchresults-product-filter__selected-initial");
    }
    else {
      document.getElementById("searchresults_appliedfilters_holder").classList.add("searchresults-product-filter__selected-initial");
    }
  }
}
function removeFilter() {
  const span = this.querySelectorAll('.searchresults-product-filter__selected-filter');
  const currentValue = span[0].getAttribute('data-value');
  appliedFilters.splice(appliedFilters.indexOf(currentValue), 1);
  updateBrowserUrl('filters', currentValue, false, false);
  performFilter();
}

function loadAllSection() {
  const currentQuery = window.location.search;
  if (currentQuery) {
    const overlay = document.getElementById("loading-screen-overlay");
    overlay.style.display = "block";
    this.style.display = "none";
    const section = this.getAttribute('data-content');
    const sectionID = `search-results-section-${section}`.toLowerCase();
    const collapseParent = `search-results-section__title_${section}`.toLowerCase();
    const collapseChild = `search-results-section__contents_${section}`.toLowerCase();
    const url = `/site-search/results${currentQuery}&section=${section}&showAll=true`;
    axios.get(url)
      .then(function (response) {
        document.getElementById(sectionID).outerHTML = response.data;
        const images = document.querySelectorAll('.js-picture-tag');
        if (images && images.length > 0) {
          imageObserver.loadPictures(images);
        }
        visibilityToggle(collapseParent, collapseChild);
        document.getElementById(collapseChild).style.display = "block";
        setSectionEventListeners(document.getElementById(sectionID));
        overlay.style.display = "none";
      })
      .catch(function () {
        setSectionEventListeners(document.getElementById(sectionID));
        overlay.style.display = "none";
      })
  }
}
function filterSection() {
  const input = document.getElementById(this.htmlFor);
  input.checked = !input.checked;
}
function filterClicked() {
  const currentElementLabel = this;
  const currentElementList = currentElementLabel.querySelectorAll('.searchresults-product-filter__list-input');
  const currentElement = currentElementList[0];
  updateBrowserUrl('filters', currentElement.value, currentElement.checked, currentElement.type === 'radio');
  if (filterAdded) {
    appliedFilters.push(currentElement.value);
    performFilter();
  }
}
function performFilter() {
  const currentQuery = window.location.search;

  if (currentQuery) {
    const overlay = document.getElementById("loading-screen-overlay");
    overlay.style.display = "block";

    const section = "Products";
    const sectionID = `search-results-section-${section}`.toLowerCase();
    const collapseParent = `search-results-section__title_${section}`.toLowerCase();
    const collapseChild = `search-results-section__contents_${section}`.toLowerCase();
    const url = `/site-search/results${currentQuery}&section=${section}`;
    axios.get(url)
      .then(function (response) {
        document.getElementById(sectionID).outerHTML = response.data;
        const images = document.querySelectorAll('.js-picture-tag');
        if (images && images.length > 0) {
          imageObserver.loadPictures(images);
        }
        visibilityToggle(collapseParent, collapseChild);
        document.getElementById(collapseChild).style.display = "block";
        filterAdded = false;
        setAppliedFilters();
        showAppliedFilters();
        setSectionEventListeners(document.getElementById(sectionID));
        overlay.style.display = "none";
      })
      .catch(function () {
        filterAdded = false;
        setSectionEventListeners(document.getElementById(sectionID));
        overlay.style.display = "none";
      })
  }
}

function setSectionEventListeners(levelArea) {
  setViewAllListeners(levelArea)
  setFilterListTitleListeners(levelArea)
  setFilterListLabelListeners(levelArea)
  setfilterSelectedItemListeners(levelArea)
}
function setViewAllListeners(levelArea) {
  const buttons = levelArea.querySelectorAll('.search-results_view_all');
  if (buttons && buttons.length > 0) {
    buttons.forEach(button => {
      button.addEventListener('click', loadAllSection, false);
    });
  }
}
function setFilterListTitleListeners(levelArea) {
  const filters = levelArea.querySelectorAll('.searchresults-product-filter__list-title');
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      filter.addEventListener('click', filterSection, false);
    });
  }
}
function setFilterListLabelListeners(levelArea) {
  const filterItems = levelArea.querySelectorAll('.searchresults-product-filter__list-label');
  if (filterItems && filterItems.length > 0) {
    filterItems.forEach(filter => {
      filter.addEventListener('click', filterClicked, false);
    });
  }

}
function setfilterSelectedItemListeners(levelArea) {
  const filterButtons = levelArea.querySelectorAll('.searchresults-product-filter__selected-list-item');
  if (filterButtons && filterButtons.length > 0) {
    filterButtons.forEach(button => {
      button.addEventListener('click', removeFilter, false);
    });
  }
}

const elements = document.querySelectorAll('.search-results-section__title');
if (elements && elements.length > 0) {
  let expand = true;
  elements.forEach(element => {
    const parent = element.id.toString();
    const child = parent.replace("__title_", "__contents_");
    visibilityToggle(parent, child);
    if (expand) {
      document.getElementById(parent).click();
      expand = false;
    }
  });
}
if (document.body.contains(document.getElementById(searchEntryFieldDesktop))) {
  document.getElementById(searchEntryFieldDesktop).addEventListener('keyup', getSearchTipsDesktop, false);
}
if (document.body.contains(document.getElementById(searchEntryFieldMobile))) {
  document.getElementById(searchEntryFieldMobile).addEventListener('keyup', getSearchTipsMobile, false);
}
if (document.body.contains(document.getElementById(searchHistoryClearDesktop))) {
  document.getElementById(searchHistoryClearDesktop).addEventListener('click', clearSearchHistory, false);
}
if (document.body.contains(document.getElementById(searchHistoryClearMobile))) {
  document.getElementById(searchHistoryClearMobile).addEventListener('click', clearSearchHistory, false);
}

document.getElementById(searchEntryFormDesktop).addEventListener("submit", function () {
  siteHeader.classList.add(siteHeaderOverlay);
  searchTipsDesktop.classList.remove(siteHeaderInfoShow);
  addSearchHistory(document.getElementById(searchEntryFieldDesktop).value);
});
document.getElementById(searchEntryFormMobile).addEventListener("submit", function () {
  siteHeader.classList.add(siteHeaderOverlay);
  searchTipsMobile.classList.remove(siteHeaderInfoShow);
  addSearchHistory(document.getElementById(searchEntryFieldMobile).value);
});

setSectionEventListeners(document);
setAppliedFilters();
showAppliedFilters();

// run at end
require('../components/base-end');
