import $ from 'jquery';
import mobileChecker from '@marshalls/mobilechecker/MobileChecker';

$(() => {
  require('jquery.scrollbar');

  let isDesktop = !mobileChecker.isDesktop(); // gets the onload to fire correctly by setting this to opposite
  let resizeTimeout;
  const scrollbars = $('.scrollbar-replacement');

  $(window).on('resize', () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(initialiseScrollbars, 150);
  });

  initialiseScrollbars();

  function initialiseScrollbars() {
    if (mobileChecker.isDesktop() && !isDesktop) {
      isDesktop = true;
      scrollbars.scrollbar();
    } else if (!mobileChecker.isDesktop() && isDesktop) {
      isDesktop = false;
      scrollbars.scrollbar('destroy');
    }
  }
});
