import axios from 'axios';

const siteBase = window.location.origin;

const linkerButtonStockist = "page-linker_inline-stockist";
const linkerButtonSubmit = "page-linker__form-submit";
const linkerInputEntry = "page-linker_inline-input";
const linkerResultsHolder = "page-linker__results";
const linkerResultsTitle = "page-linker__results-title";
const linkerResultsList = "stockist_search_list";
const linkerResultsClass = "page-linker__form-results-hidden";

function getStockists() {
  const postCode = document.getElementById(linkerInputEntry).value;
  if (!postCode) {
    document.getElementById(linkerButtonSubmit).click();
  }
  else {
    document.getElementById(linkerButtonStockist).disabled = true;
    const url = `${siteBase}/getstockists_bypostcode/${postCode}/false`;
    axios.get(url)
      .then(function (response) {
        const stockists = response.data.Stockists;
        if (stockists && stockists.length > 0) {
          const resultsView = document.getElementById(linkerResultsList);
          resultsView.innerHTML = "";
          for (const stockist of stockists) {
            const contentString = `<dt><span class="title">${stockist.Title}</span><span class="details">Phone: ${stockist.Telephone}</span></dt>`;
            resultsView.insertAdjacentHTML('beforeend', contentString);
          }

          document.getElementById("postcode").value = postCode;
          document.getElementById(linkerResultsTitle).innerHTML = `Stockists local to ${postCode.toUpperCase()}`;
          document.getElementById(linkerResultsHolder).classList.remove(linkerResultsClass);
          document.getElementById(linkerButtonStockist).disabled = false;
        }
      })
      .catch(function () {
        document.getElementById(linkerButtonStockist).disabled = false;
      })
  }
}

if (document.body.contains(document.getElementById(linkerInputEntry))) {
  document.getElementById(linkerInputEntry).addEventListener("keypress", function (e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (window.innerWidth <= 768) {
        document.getElementById(linkerButtonSubmit).click();
      }
      else {
        getStockists();
      }
    }
  });
}
if (document.body.contains(document.getElementById(linkerButtonStockist))) {
  document.getElementById(linkerButtonStockist).addEventListener("click", getStockists);
}
