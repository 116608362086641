const navigateButton = document.getElementById("pagetop__navigation");

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    navigateButton.style.display = "block";
  } else {
    navigateButton.style.display = "none";
  }
}
function toTopFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
window.addEventListener('scroll', scrollFunction, false);
if (document.body.contains(document.getElementById("pagetop__navigation"))) {
  document.getElementById("pagetop__navigation").addEventListener("click", toTopFunction, false);
}
