import axios from'axios'

require('./init-form-date-picker');
require('./init-form-file-upload');
require('./init-form-validation');

const highlightClass = "highlight";

function stopDoubleSubmission() {
  this.disabled = false;
}
function formSubmission() {
  const overlay = document.getElementById("loading-screen-overlay");
  overlay.style.display = "block";
}
function checkFormExists() {
  if (document.body.contains(document.getElementById("stonemarket_form_submission_button"))) {
    document.getElementById("stonemarket_form_submission_button").removeEventListener("click", stopDoubleSubmission);
    document.getElementById("stonemarket_form_submission_button").addEventListener("click", stopDoubleSubmission, false);
  }
  if (document.body.contains(document.getElementById("stonemarket_form_submission"))) {
    document.getElementById("stonemarket_form_submission").removeEventListener("submit", formSubmission);
    document.getElementById("stonemarket_form_submission").addEventListener("submit", formSubmission, false);
  }
  const fileUploads = document.getElementsByClassName("form__control--file-label");
  if (fileUploads && fileUploads.length > 0) {
    for (const fileUpload of fileUploads) {
      const fileInput = getRelatedFileElement(fileUpload, ".form__control--file");

      fileUpload.addEventListener("dragover", function (e) {
        e.preventDefault();
        inputHighlightAdd(this);
      });
      fileUpload.addEventListener("dragenter", function (e) {
        e.preventDefault();
        inputHighlightAdd(this);
      });
      fileUpload.addEventListener("drop", function (e) {
        e.preventDefault();
        const dataTransfer = e.dataTransfer || e.originalEvent.dataTransfer;
        fileInput.files = dataTransfer.files;
        updateFileList(fileUpload, dataTransfer.files);

        fileUpload.classList.remove(highlightClass);
      });
      fileUpload.addEventListener("dragexit", function (e) {
        e.preventDefault();
        inputHighlightRemove(this);
      });
      fileUpload.addEventListener("dragleave", function (e) {
        e.preventDefault();
        inputHighlightRemove(this);
      });

      fileInput.addEventListener("change", function (e) {
        updateFileList(fileUpload, e.currentTarget.files);
      })
    }
  }
}
function getRelatedFileElement(label, className) {
  const parent = label.parentNode;
  return parent.querySelector(className);
}
function inputHighlightAdd(label) {
  label.classList.add(highlightClass);
}
function inputHighlightRemove(label) {
  label.classList.remove(highlightClass);
}
function updateFileList(fileUpload, files) {
  const fileList = getRelatedFileElement(fileUpload, ".form__control--file-list");
  fileList.innerHTML = "";

  if (files.length) {
    for (let i = 0; i < files.length; i++) {
      fileList.classList.remove('hide-text');
      fileList.appendChild(document.createTextNode(`${files.item(i).name}`));
      fileList.appendChild(document.createElement("br"));
    }
  } else {
    fileList.classList.add('hide-text');
  }
}
function showFormEntry() {
  const path = document.getElementById("path").value;
  const formName = this.value;
  const action = document.forms['multi-form__selector-form'].action;
  const url = encodeURI(`${action}/?path=${path}&formCodeName=${formName}&IsAxiosRequest=true`);
  const overlay = document.getElementById("loading-screen-overlay");
  overlay.style.display = "block";
  axios.get(url)
    .then(function (response) {
      document.getElementById("multi-form__selected-form").innerHTML = "";
      document.getElementById("multi-form__selected-form").innerHTML = response.data;
      checkFormExists();
      overlay.style.display = "none";
    })
    .catch(function () {
      overlay.style.display = "none";
    })
}

if (document.body.contains(document.getElementById("multiForm_selectorelement"))) {
  document.getElementById("multiForm_selectorelement").addEventListener("change", showFormEntry, false);
}
checkFormExists()
