import $ from 'jquery';

$(() => {
  const $popSearches = $('.js-pop-search-card');

  if ($popSearches.length) {
    require.ensure(['./core/popular-search-view-all.js'], require => {
      require('./core/popular-search-view-all.js');
    });
  }
});
