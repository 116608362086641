// template
require('./base');

// components
require('./init-forms');
require('./init-section-navigation');
require('./init-to-top-navigation');
require('./init-faqs');
require('./init-hero-carousel');
require('./init-product-carousel');
require('./init-featured-products-carousel');
require('./init-dop');
require('./init-uspBanner');
require('./init-drop-down');
require('./init-page-linker');

// run this at end
require('./base-end');
