import $ from 'jquery';

$(() => {
  if (!$(".form__control--file").length) {
    return;
  }

  require.ensure(['./core/forms/file-upload.js'], require => {
    require('./core/forms/file-upload');
  });
});
