import $ from 'jquery';

$(() => {
  if (!$(".dop-display__holder").length) {
    return;
  }
  require.ensure(['./core/dop.js'], require => {
    require('./core/dop.js');
  });
});
