import $ from 'jquery';

$(() => {
  if (!$(".drop-down__text__holder__list__term").length) {
    return;
  }
  require.ensure(['./core/drop-down.js'], require => {
    require('./core/drop-down.js');
  });
});
