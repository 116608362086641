import $ from 'jquery';

$(() => {
  if (!$(".js-image-carousel").length) {
    return;
  }
  require.ensure(['./core/hero-carousel.js'], require => {
    require('./core/hero-carousel.js');
  });
});
