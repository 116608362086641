import $ from 'jquery';
import sectionNavHelper from "../classes/SectionNavigationHelper";

$(() => {
  const $window = $(window);

  let resizeTimeout;

  $window.on('resize', () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(resizeFunction, 150);
  });

  function resizeFunction() {
    sectionNavHelper.refreshAll();
  }
});
