import $ from 'jquery';

$(() => {
  if (!$(".js-date-picker").length) {
    return;
  }

  require.ensure(['./core/forms/date-picker.js'], require => {
    require("jquery-ui/ui/widgets/datepicker")
    require('./core/forms/date-picker');
  });
});
