import * as $ from 'jquery';

$(() => {
  if (!$(".js-picture-tag,.js-image-tag").length) {
    return;
  }

  require.ensure(['./core/dom-image-observer.js'], require => {
    require('./core/dom-image-observer');
  });
});
