const Velocity = require('velocity-animate');

function visibilityToggle(parentID, childID) {
  if (document.getElementById(parentID) != null) {
    document.getElementById(parentID).addEventListener('click', function () {
      const block = document.getElementById(childID);
      const show = document.getElementById(childID).classList.contains('hidden');
      if (show) {
        Velocity(block, 'slideDown', { duration: 400, easing: 'easeOut' });
        document.getElementById(childID).classList.remove('hidden');
        document.getElementById(parentID).classList.remove('faq-list__title-expanding');
        document.getElementById(parentID).classList.add('faq-list__title-collapsing');
      }
      else {
        Velocity(block, 'slideUp', { duration: 400, easing: 'easeOut' });
        document.getElementById(childID).classList.add('hidden');
        document.getElementById(parentID).classList.remove('faq-list__title-collapsing');
        document.getElementById(parentID).classList.add('faq-list__title-expanding');
      }
    })
  }
}

const elements = document.querySelectorAll('.faq-list__title');
if (elements && elements.length > 0) {
  elements.forEach(element => {
    const parent = element.id.toString();
    const child = parent.replace("__title__", "__answer__");
    visibilityToggle(parent, child);
  });
}
