import $ from 'jquery';

class SectionNavigationHelper {
  constructor() {
    this.$container = $('.section-navigation');
    this.hasSectionNav = false;

    if (this.$container.length) {
      this.hasSectionNav = true;

      this.$sections = this.getSections();
      this.$window = $(window);
      this.$listItems = $('.section-navigation__list-item');
    }
  }

  refreshAll() {
    if (this.hasSectionNav) {
      this.setupContainer();
      this.setScroll();
      this.setStuckAndActive();
    }
  }

  getSections() {
    const sections = [];

    $('.section-navigation__link').each((index, link) => {
      const $section = $($(link).attr('href'));

      if ($section.length) {
        sections.push($section);
      }
    });

    return $(sections);
  }

  setupContainer() {
    if (!Modernizr.csspositionsticky) {
      if (!this.$container.parent().hasClass('section-navigation__wrapper')) {
        this.$container.wrap('<div class="section-navigation__wrapper"></div>');
        this.$containerWrapper = this.$container.parent();
      }

      this.$containerWrapper.height(this.$container.outerHeight());
    } else {
      this.$container.closest('section').addClass('section-navigation__section');
    }
  }

  setScroll() {
    this.$window.off('scroll.sectionNavigation');
    this.$window.on('scroll.sectionNavigation', () => {
      this.setStuckAndActive();
    });
  }

  setStuckAndActive() {
    const scrollTop = this.$window.scrollTop();

    const $section = this.$sections.filter((index, section) => {
      const $thisSection = $(section);
      const offsetTop = parseInt($thisSection.offset().top, 10) - parseInt(this.$container.outerHeight(), 10) - 5;
      const bottom = offsetTop + parseInt($thisSection.outerHeight(), 10);

      return scrollTop >= offsetTop && scrollTop < bottom;
    });

    if (!Modernizr.csspositionsticky) {
      if (scrollTop >= this.$containerWrapper.offset().top) {
        this.$container.addClass('stuck');
      } else {
        this.$container.removeClass('stuck');
      }
    }

    if ($section.length) {
      this.$listItems.removeClass('active');
      this.$listItems.eq(this.$sections.index($section.eq(0))).addClass('active');
    }
  }
}

const instance = new SectionNavigationHelper();

export default instance;
