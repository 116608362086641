import $ from 'jquery';

$(() => {
  if (!$('.cookie-panel').length) {
    return;
  }

  require.ensure(['./core/cookie-panel.js'], require => {
    require('./core/cookie-panel.js');
  });
});
