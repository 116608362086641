import $ from 'jquery';

$(() => {
  const $subCatList = $('.product-page__subcategory-list');

  if ($subCatList.length) {
    require.ensure(['./core/category-page-subcategory-list.js'], require => {
      require('./core/category-page-subcategory-list.js');
    });
  }

  const $productList = $('.product-page__products');

  if ($productList.length) {
    require.ensure(['./core/category-page-products.js'], require => {
      require('./core/category-page-products.js');
    });
  }

  const $viewAllButtons = $('.product-page__view-all');

  if ($viewAllButtons.length) {
    require.ensure(['./core/category-page-view-all-ajax.js'], require => {
      require('./core/category-page-view-all-ajax.js');
    });
  }
});
