import * as $ from 'jquery';

$(() => {
  if (!$(".section-navigation").length) {
    return;
  }

  require.ensure(['./core/section-navigation.js'], require => {
    require('./core/section-navigation');
  });
});
